.accordion-body-reasign {
	padding: 10px;
}

.accordion-body-reasign > div {
	height: calc(100vh - 130px);
}

.accordion-body-reasign .title {
	color: var(--header-icon-color);
	font-size: 16px;
	text-align: center;
	font-weight: 400;
}

.accordion-body-reasign .subtitle {
	margin: 30px 0 15px 0;
	max-height: 300px;
}

.accordion-body-reasign .container-dni {
	display: flex;
}

.accordion-body-reasign .container-dni div:first-child {
	width: 50px;
	padding-right: 5px;
}

@media only screen and (max-width: 780px) {

}
/* graph */
.dotdiastoleLine {
    fill: #298C24;
    stroke: #fff;
}
.sistole{
    fill: #46DAFB;
    stroke: #fff;
}
/* 298C24 */
.lineSistole {
    fill: none;
    stroke: #46DAFB;
    stroke-width: 1;
}
.lineRed {
    fill: none;
    stroke: #D90B16;
    stroke-width: 1;
}
.dotlineRed {
    fill: #D90B16;
    stroke: #fff;
}
.diastoleLine {
    fill: none;
    stroke: #298C24;
    stroke-width: 1;
}
.y.axis > .tick >line,
.x.axis  > .tick >line{
    stroke: transparent;
}
.overlay {
  fill: none;
  pointer-events: all;
}
/* Style the dots by assigning a fill and stroke */

/*
.dot {
    fill: #46DAFB;
    stroke: #fff;
} */
.domain{
    stroke: gray;
    stroke-width: 0.1px;
}
  .focus circle {
  fill: none;
  stroke: steelblue;
}
.grid.y{
    stroke: gray;
    stroke-width: 0.1px;
}
.grid.x{
    stroke: gray;
    stroke-width: 0.1px;
}
#char1{
    /* margin: 0 auto; */
    display: grid;
    align-items: center;
}