/* @font-face {
	font-family: Flama;
	src: url("./utils/flama_font/Flama-Basic.woff") format("woff");
}
@font-face {
	font-family: Flama;
	src: url("./utils/flama_font/Flama-Bold.woff") format("woff");
	font-weight: bold;
}
@font-face {
	font-family: Flama;
	src: url("./utils/flama_font/Flama-BasicItalic.woff") format("woff");
	font-style: italic;
} */
@font-face {
	font-family: Open_sans;
	src: url("./utils/open_sans_font/OpenSans.woff") format("woff"); /* Modern Browsers */
}
@font-face {
	font-family: Open_sans;
	src: url("./utils/open_sans_font/OpenSans-Bold.woff") format("woff"); /* Modern Browsers */
	font-weight: bold;
}
@font-face {
	font-family: Open_sans;
	src: url("./utils/open_sans_font/OpenSans-Italic.woff") format("woff"); /* Modern Browsers */
	font-style: italic;
}

@font-face {
	font-family: Roboto;
	src: url("./utils/roboto_font/Roboto-Regular.woff") format("woff");
}
@font-face {
	font-family: Roboto;
	src: url("./utils/roboto_font/Roboto-Bold.woff") format("woff");
	font-weight: bold;
}
@font-face {
	font-family: Roboto;
	src: url("./utils/roboto_font/Roboto-Italic.woff") format("woff");
	font-style: italic;
}

:root {
	--header-icon-color: #004765;
	--helper-text-color: #0097c9;
	--button-color: #00bfef;
	--alert-color: #c41f1f;
	--message-color: #11b155;
	--background-color: #f4f4f4;
	--default-text-color: #444;
	--muted-text-color: #d4d4d4;
	--clendar-width: 0.9rem;
	--calendar-height: 0.9rem;
	--calendar-font-size: 9px;
	--zebra-shade-color: #f2f2f2;
	--title-color-green: #48c780;
	--font-color-black: #666666;
	--field-extra-color: #abb5194f;
}

/* elements helpers */
* {
	box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
	color: transparent;
}

input::-webkit-datetime-edit {
	color: transparent;
}
input.filled::-webkit-datetime-edit {
	color: #000;
}

.content a {
	text-decoration: none;
}

html, body, #root {
	margin: 0;
	padding: 0;
}

body {
	font-size: 14px;
	margin: 0;
	font-family: "Open_sans", Fallback, "Roboto";
	color: var(--default-text-color);
	background: var(--zebra-shade-color);
}

form {
	width: 100%;
	margin: 0 auto 1em;
}

label {
	font-size: 13px;
}

.select-field select {
	z-index: 2;
}

.select-field label {
	z-index: 2;
}

/* bug accordion */
.accordion__item {
	position: relative;
}
.accordion-title-close {
	right: 11px;
	position: absolute;
}

/* Layout helpers */

.flex {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.f-c {
	flex-direction: column;
}
.f-r {
	flex-direction: row;
}

.f-w {
	flex-wrap: wrap;
}

.a-s {
	-webkit-align-self: flex-end;
	align-self: flex-end;
}

.j-s {
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
}

.j-sa {
	-webkit-justify-content: space-around;
	-ms-flex-pack: space-around;
	justify-content: space-around;
}

.j-e {
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
	justify-content: flex-end;
}

.companion_names .j-e {
  justify-content: flex-start;
}

.j-c {
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.j-sb {
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	justify-content: space-between;
}

.j-se {
	-webkit-justify-content: space-evenly;
	-ms-flex-pack: space-evenly;
	justify-content: space-evenly;
}

.a-i-s {
	align-items: flex-start;
}

.a-i-c {
	-webkit-align-items: center;
	-ms-flex-line-pack: center;
	align-items: center;
}

.a-i-e {
	-webkit-align-items: flex-end;
	-ms-flex-line-pack: flex-end;
	align-items: flex-end;
}

.a-c-c {
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
}

.p-relative {
	position: relative;
}

.w-100 {
	width: 100%;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-bold {
	font-weight: bold;
}

.form-inline {
	display: flex;
	width: 100%;
	margin: 0.2em 0;
	align-items: center;
}

.form-group {
	width: 100%;
	text-align: left;
	padding: 0.2em 0;
}

.margin-0-auto {
	margin: 0 auto !important;
}

.mt-2 {
	margin-top: 2em;
}

.mt-1 {
	margin-top: 1em;
}
.mt-1px {
	margin-top: 1px;
}

.mb-1 {
	margin-bottom: 1em;
}
.mb-2 {
	margin-bottom: 1em;
}

.mb-3 {
	margin-bottom: 3em;
}

.mr-3 {
	margin-right: 3em;
}
.ml-3 {
	margin-left: 3em;
}
.ml-1 {
	margin-left: 1em;
}
.ml-2 {
	margin-left: 2em;
}
.mr-03 {
	margin-right: 0.3em;
}
.ml-03 {
	margin-left: 0.3em;
}

.mr-02 {
	margin-right: 0.2em;
}
.ml-02 {
	margin-right: 0.2em;
}
.pt-02 {
	padding-top: 0.2em;
}

.pt-1 {
	padding-top: 1em;
}

.pb-1 {
	padding-bottom: 1em;
}

.p-20 {
	padding: 20px;
}

.font-18 {
	font-size: 18px;
}

.font-14 {
	font-size: 14px;
}

.clickable {
	cursor: pointer;
}

/* Custom color helpers */

.header,
.icon {
	color: var(--header-icon-color);
}

.helper-msg {
	color: var(--helper-text-color);
	margin-top: 0;
}

.error-msg {
	color: var(--alert-color);
	margin-top: 0;
}

.success-msg {
	color: var(--message-color);
	margin-top: 0;
}

.button-msg {
	color: var(--button-color);
}

.disabled-ripple:hover {
	background-color: pink;
}

/* Button style */
.button {
	color: white;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 12px 18px;
  font-size: 16px;
  box-shadow: 0 0 4px #999;
	font-family: "Open_sans", Fallback, "Roboto";
  border-radius: 2px;
  background-color: #2196f3;
}
.button:hover {
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.23);
}
.button span::before {
	color: #fff;
}

.button-lg {
	font-size: 15px;
	background-color: var(--button-color);
	padding: 0.8em 2.5em;
}

.button-sm {
	font-size: 14px;
	width: 135px;
	height: 30px;
	padding: 0;
	margin: 0 0.2em;
}

.button-md {
	font-size: 14px;
  width: fit-content;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	margin: 0 0.2em;
  display: flex;
  align-items: center;
}

.btn-success {
	color: #fff;
	background: var(--message-color);
}
.btn-alert {
	color: #fff;
	background: var(--alert-color);
}
.btn-muted, .btn-gray {
	color: #fff;
	background: var(--muted-text-color);
}

.bg-icon {
	background-color: var(--header-icon-color);
}

.bg-default {
	background-color: var(--background-color);
}

.bg-primary {
	background-color: var(--button-color);
}

.bg-muted {
	background-color: var(--muted-text-color);
}

.bg-zebra-shade {
	background-color: var(--zebra-shade-color);
}

.muted-text {
	color: var(--muted-text-color);
}

.wrong-input input,
.wrong-input label {
	color: var(--alert-color) !important;
	border-color: var(--alert-color);
}

.wrong-input label#custom-file label {
	color: #fff !important;
}

/* PAGES */

.home-container {
	height: 100%;
	background-color: var(--background-color);
}
.content {
	margin: 60px 6px 0px 57px;
	background: #fff;
	transition: all 0.2s ease-in;
	overflow: auto;
}

.users-title,
.accordion__title,
.resources-title {
	color: #fff;
	width: inherit;
	display: flex;
	padding: 11px;
	font-size: 14px;
	align-items: center;
	background-color: var(--header-icon-color);
}
.accordion {
	overflow: auto;
	border-width: 0;
}

.search-field-icon span.before-icon {
	position: relative;
	top: 5px;
	font-size: 16px;
}

/* Tables */
.list {
	border-collapse: collapse;
	width: 100%;
}

.list a {
	color: var(--default-text-color);
}

.list th {
	font-size: 14px;
	color: var(--header-icon-color);
}

.list td,
.list th {
	border-right: 1px solid #ddd;
	padding: 8px;
}

.list tr {
	padding: 2px 10px;
}

.list th:last-child,
.list td:last-child {
	border-right: none;
}

.list tr:nth-child(odd) {
	background-color: var(--zebra-shade-color);
}

.list thead tr:first-child {
	background-color: #fff;
}

.list .list-item:hover {
	background-color: var(--header-icon-color) !important;
	color: #fff !important;
}

/* General styles */

.disable-background {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 5;
	opacity: 0;
	transition: opacity 0.2s ease-in;
}

.collapsed-content {
	margin-left: 207px;
}

.withoutMargin {
	margin: 0;
}

.withoutMargin .container {
  max-width: 100% !important;
}

@media only screen and (max-width: 780px) {
	.logged-info {
		display: none;
	}

	.filter-field:first-child {
		border: none;
	}

	.header-bar > img {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
	}

	.mobile-toggle {
		display: block;
		float: left;
	}

	.content {
		margin: 60px 6px 0px 6px;
	}

	.sidebar {
		top: 0;
		height: 100%;
		z-index: 10;
	}

	.collapsed {
		left: -200px;
	}

	.toggle-icon {
		display: none;
	}

	.collapsed-content .disable-background {
		width: 100%;
		height: 100%;
		z-index: 5;
		opacity: 1;
	}
}
input[type="radio"] {
	/* -webkit-appearance: inherit;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 20px;
	height: 20px;
	position: relative; */

  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]:checked:after {
	/* content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
	background-color: white;
  border: 3px solid rgb(18, 177, 85); */
  transform: scale(1);
}

input[type="radio"]:after {
	/* content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
	background-color: white;
  border: 3px solid grey; */
  content: "";
  width: 1em;
  height: 1em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

/* button.react-calendar__tile.react-calendar__month-view__days__day {
	height: 1.1rem !important;
}
.react-calendar__tile--active time {
	border-radius: 25% !important;
} */
/* font-size: 10px; */
select:disabled {
	-webkit-appearance: none;
	appearance: none;
}

/* buttons in form */
.user-form-actions {
	min-height: 65px;
	justify-content: flex-end;
}

/*!
 * ripple efect fro css
 * http://fian.my.id/Waves
 */
.ripple,
.ripple-gray,
.ripple-title,
.ripple-btn-gray,
.ripple-btn-alert
{
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #0071a0 radial-gradient(circle, transparent 1%, #0071a0 1%) center/15000%;
  /* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%; */
}
.ripple:active {
  background-color: var(--header-icon-color) !important;
  background-size: 100%;
  transition: background 0s;
}
.ripple-title:hover {
	background: #005a80 radial-gradient(circle, transparent 1%, #005a80 1%) center/15000%;
}
.ripple-title:active {
	background-color: var(--header-icon-color) !important;
	background-size: 100%;
  transition: background 0s;
}
.ripple-gray:hover,
.ripple-btn-gray:hover
{
	background: #ebebeb radial-gradient(circle, transparent 1%, #ebebeb 1%) center/15000%;
}
.ripple-gray:active,
.ripple-btn-gray:active
{
  transition: background 0s;
	background-size: 100%;
	background-color: #999 !important;
}
.ripple-btn-alert:hover {
	background: var(--alert-color) radial-gradient(circle, transparent 1%, var(--alert-color) 1%) center/15000%;
}
.ripple-btn-alert:active {
  transition: background 0s;
	background-size: 100%;
	background-color: #8f0101;
}

.extra-color {
	background-color: var(--field-extra-color) !important;
}

.dowloadLeeRealiza{
  font-size: 12px;
  margin-left: 20px;
  border: #000 1px solid;
  border-radius: 3px;
  padding: 1px 3px;
  text-decoration: none;
  color: #000;
  background-color: #efefef;
}

input[type="checkbox"]:checked:before {
	content: none;
}

.index-input select, .index-input label {
	z-index: auto !important;
}

.custom-input .form-input {
	padding: 0;
}

.custom-input .form-input .form-input-box {
	height: 35px;
}

.custom-input .form-input .label-input-full {
	top: -22px
}

.index-input label {
	margin: 0;
}
