h1 {
    color: #14455e;
    font-size: 14pt !important;
    font-weight: bold !important;
}

h2 {
    font-size: 12pt !important;
    font-weight: bold !important;
}

img#logo {
    float: right;
    width: 100px;
}

p#physician span {
    display: block;
}

p#signature span {
    display: block;
}