.app-page-title {
  padding: 30px;
  position: relative
}

.app-page-title+.body-tabs-layout {
  margin-top: -30px !important
}

.app-page-title .page-title-wrapper {
  position: relative;
  display: flex;
  align-items: center
}

.app-page-title .page-title-heading,
.app-page-title .page-title-subheading {
  margin: 0;
  padding: 0
}

.app-page-title .page-title-heading {
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  align-content: center;
  align-items: center
}

.app-page-title .page-title-subheading {
  padding: 3px 0 0;
  font-size: .88rem;
  opacity: .6
}

.app-page-title .page-title-subheading .breadcrumb {
  padding: 0;
  margin: 3px 0 0;
  background: 0 0
}

.app-page-title .page-title-actions {
  margin-left: auto
}

.app-page-title .page-title-actions .breadcrumb {
  margin: 0;
  padding: 0;
  background: 0 0
}

.app-page-title .page-title-icon {
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: .83333rem;
  margin: 0 30px 0 0;
  background: #fff;
  box-shadow: 0 .46875rem 2.1875rem rgba(4, 9, 20, .03), 0 .9375rem 1.40625rem rgba(4, 9, 20, .03), 0 .25rem .53125rem rgba(4, 9, 20, .05), 0 .125rem .1875rem rgba(4, 9, 20, .03);
  border-radius: .25rem;
  width: 60px;
  height: 60px
}

.app-page-title .page-title-icon i {
  margin: auto
}

.app-page-title .page-title-icon.rounded-circle {
  margin: 0 20px 0 0
}

.app-page-title+.RRT__container {
  margin-top: -23.07692px
}

.app-page-title.app-page-title-simple {
  margin: 0;
  background: 0 0 !important;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0
}

.cards {
  background: var(--zebra-shade-color);
  width: 100%;
  padding: 10px;
}

.cardw {
  background: var(--zebra-shade-color);
  width: 100%;
  padding: 10px;
}

.cardz {
  background: white;
  width: 100%;
  padding: 10px;
  border: 1px solid #CCC;
  border-radius: 6px;
}

.form-group {
  margin-bottom: 1rem
}
