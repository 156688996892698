#ampaView {
    padding-left: 4vw;
    padding-right: 4vw;
}
#ampaView .drid-3{
    display: grid;
    justify-items: center;
    grid-template-columns: 45% 45%;
}
.drid-3 tr:nth-child(odd) {
    background-color: #f2f2f2;
}
.drid-3 tr td:nth-child(2) {
    text-align: inherit;
}
#ampaView table{
    border-collapse: collapse;
    /* font-size: 0.9em; */
    width: 100%;
}

#ampaView  .end{
    color:green
}
#ampaView .progress{
    color:orange
}
#ampaView .title {
    font-weight: bold;
    background-color: white !important;
    color:#274764;
    margin-bottom: 5px;
}
/* #ampaView .titleinformacion {
    text-align: center;
} */
#ampaView .blueColor{
    color:#274764;
}
#ampaView .padding-10{
    margin: 10px;
}   
#ampaView .w100{
    width: 100%;
}
#ampaView .w80{
    width: 80%;
}
#ampaView .thead{
    display: grid;
    grid-template-columns: 19% 27% 27% 27%;
    text-align:center;
}
#ampaView .body-fake{
    display: grid;
    grid-template-columns: 25% 13.5% 13.5% 13.5% 13.5% 13.5% 13.5%;

    text-align:center;
}
.body-fake .back-ground{
    background: #F2F2F2;
}
#ampaView .border{
    border-right: 1px #EAEAEA solid;
    
}
#ampaView .bordertop{

    border-top: 1px #EAEAEA solid;
}
#ampaView .borderbottom {

    border-bottom: 1px #EAEAEA solid;
}
#ampaView .borderleft {

    border-left: 1px #EAEAEA solid;
    font-weight: bold;
}
#ampaView  .file{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    text-align: center;
    width: 100%;
}
#ampaView .aditionalInfo{
    padding: 10px 10px 10px 0;
}
#ampaView .thM{
    font-weight: bold;
}
.sectionGeneral{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    column-gap:20px
}
.information-general{
    margin-top: 20px;
    grid-column: span 4/span 4;
}
.takes-patient{
    margin-top: 45px;
    grid-column: span 8/span 8;
    padding-right: 20px;
}


  @media only screen and (max-width: 992px) {
      
    #ampaView {
        padding-left: 1vw;
        padding-right: 1vw;
    }
    #ampaView .drid-3{
        padding-left: 10px;
        padding-right: 10px;
        display: grid;
        justify-items: center;
        grid-template-columns: 45% 45%;
    }
  }
  @media only screen and (max-width: 810px) {
    
    #ampaView .drid-3{
        padding-left: 10px;
        padding-right: 10px;
        display: grid;
        justify-items: center;
        grid-template-columns: 95%;
    }
    .sectionGeneral{
        display: flex;
        flex-direction: column;
        column-gap:20px
    }
    .information-general{
        padding-right: 20px;
    }
    .takes-patient{
        margin-top: 15px;
        grid-column: span 8/span 8;
        padding-right: 20px;
    }
  }

  button.button-sm {
      margin: 10px 0 !important;
  }