
#PatientInfoAmpa .head{
    font-size: 12px;
    color: var(--helper-text-color);
    webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
}
#PatientInfoAmpa .input-field>label{
    color: #235F74;
}
#PatientInfoAmpa .profileImage{

    width: 100px;
    height: 100px;
    margin: 13px auto !important;
    min-width: 100px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}
#PatientInfoAmpa .head{
    display: grid;
    grid-template-columns: 35% 35% 30%;
    align-items: center;
}
#PatientInfoAmpa .personalInfo{
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
}
.borderRightGray{
    border-right: #D8D9D9 1px solid;
    border-top: 20px transparent solid;
    border-bottom: 20px transparent solid;
}
#PatientInfoAmpa .personalInfo .directData, #PatientInfoAmpa .otherInfo{
    font-size: 15px;
    display: grid;
    align-items: center;
    justify-content: center;
}
#PatientInfoAmpa .bodyForm .head{
    background-color: var(--header-icon-color);
    color: #fff;
    line-height: 36px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 14px;
}
#PatientInfoAmpa .bodyForm .container-3 {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    grid-gap: 3%;
}
#PatientInfoAmpa .bodyForm .container-3 .one{
    background-color: #dcdcdc47;
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 20px;
    margin-left:10px ;
}
#PatientInfoAmpa .bodyForm .container-3 .two{
    background-color: #dcdcdc47;
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    height: 100%;
}
#PatientInfoAmpa .bodyForm .container-3 .three{
    background-color: #dcdcdc47;
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    height: 100%;
    margin-right: 10px;
}

#PatientInfoAmpa .threeInputs{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.8em;
    margin-top: 10px;
}
.optionsClick{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1%;
    color: black;
}
.selectbox{
    width: 100%;
    background:#ccc;
}
.selected1{
    background-color: red;
}
.selected2{
    background-color: rgb(255, 114, 0);
}
.selected3{
    background-color: rgb(255, 179, 0);
}
.selected4{
    background-color: rgb(205, 220, 57);
}
.selected5{
    background-color: rgb(139, 195, 74);
}
.two .texto{
    padding: 10px;
    display: grid;
    width: 100%;
    height: 97px;
}
.two .title {
    padding: 10px;
    color: #235F74;
}
.licoresOptions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    background: white;
}
.sectionCheckBox{
    width: 100%;
    background: white;
    display: grid;
}
.sectionCheckBox .form-group{
    height: 40px;
}
.containerNotas{
    margin-left:10px;
}
.enfermeraAmpa,
.containerNotas, .containerEquipo{
    background-color: #dcdcdc47;
    display: grid;
    justify-items: start;
    align-content: start;
    padding: 10px;
    
    height: 100%;
    margin-bottom: 10px;
}
.containerNotas .form-textarea textarea{
    background-color: white;
}
.containerEquipo{
    padding: 30px;
    margin-right: 10px;

}
.enfermeraAmpa {
    padding-top: 30px;
}
.medicamentosPanel{
    display: grid;
    width: 100%;
    
}

.container-5{
    background-color: #dcdcdc47;
    display: grid;
    grid-template-columns: 50% 12% 12% 12% 12%;
    margin:10px;
    margin-bottom: 10px;
}
.container-tool{
    padding: 20px;
}
.agregar-button{
    background-color:#235F74;
    color: white;
    padding: 10px;
    text-align: center;
}
.medicamentosPanel .row{
    padding: 5px;
    display: grid;
    justify-content: center;
}
.medicamentosPanel .rowback{
    background-color: lightgray;
    display: grid;
    justify-content: center;
}
.medicamentosPanel .container-5{
    justify-content: center;
}
.medicamentosPanel .delete {
    color: #235F74;
    border-radius: 50%;
    width: 19px;
    font-size: 14px;
    vertical-align: middle;
    border: solid 1px;
    background: white;
    line-height: 0;
    display: grid;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1px;
    height: 19px;
}
.container-create{
    display: grid;
    justify-content: center;
}
.container-create .crear-ampa-button{
    background: #235F74;
    color: white;
    padding: 5px;
    margin-bottom: 10px;
}
#PatientInfoAmpa .error{
    border-bottom: solid 1px red;
    width: 100%;
}
.enfermeraAmpa >div {
    width: 100%;
}
.container-tool, .row{

    border-right: 2px white solid;
}
#PatientInfoAmpa .row{
    border-top: 2px white solid;
}
#PatientInfoAmpa .provicional{
    padding: 20px;
    display: grid;
    justify-content: center;
    grid-template-columns: 30% 30%;
    grid-gap: 20px;
}
#PatientInfoAmpa .medicines{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: #f2f2f2;
}
.medicines .back-b{
    background-color: white;
}
@media only screen and (max-width: 992px) {
    #PatientInfoAmpa .head {
        grid-template-columns: 50% 50%;
    }
    #PatientInfoAmpa .personalInfo {
    grid-template-columns: 50% 50%;
    }
}
@media only screen and (max-width: 600px) {
    #PatientInfoAmpa .head {
        grid-template-columns: 100%;
    }
    #PatientInfoAmpa .personalInfo {
    grid-template-columns: 100%;
    }
    
}
.hide{
    display: none;
}
